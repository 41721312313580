/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.qv-table-layout-h {
  display: grid;
  grid-template-columns: 60% 1fr;
  gap: 24px;
}
 /deep/ .ant-table-row-expand-icon {
  margin-right: 0 !important;
}
.usergroup-item {
  position: relative;
}
.usergroup-item .usergroup-item-hd {
  height: 40px;
  display: flex;
  align-items: center;
}
.usergroup-item:before {
  content: '';
  position: absolute;
  left: 10px;
  width: 0;
  top: 40px;
  bottom: 20px;
  border-left: 1px dashed #999;
}
.usergroup-item .action-icon {
  color: #2d63ff;
  margin-left: 15px;
  cursor: pointer;
  font-size: 20px;
}
.usergroup-item .usergroup-user-list {
  color: #999;
  padding-top: 20px;
}
.usergroup-item .usergroup-user-list .usergroup-user-item {
  position: relative;
  height: 40px;
  padding-left: 40px;
  display: flex;
  align-items: center;
}
.usergroup-item .usergroup-user-list .usergroup-user-item:before {
  content: '';
  position: absolute;
  left: 10px;
  width: 30px;
  top: 20px;
  height: 0;
  border-top: 1px dashed #999;
}
